<template>
  <div class="yb-view">
    <div v-if="jobs && jobs.length" class="yb-view-header">
      <div v-if="jobs.length > 0" class="w-full flex flex-row justify-between items-center py-2">
        <div class="yb-menu-heading !p-0">
          Total ({{ jobs.length }})
        </div>
        <div class="input-wrapper mr-1 text-sm">
          <yb-search-input v-model="jobsSearch" placeholder="Search Jobs" data-test-id="search-jobs" input-classes="w-full py-1 px-1 yb-input-base" />
        </div>
      </div>
    </div>
    <div class="yb-view-content yb-view text-sm font-light pb-24">
      <table class="yb-view-content block !h-full select-none">
        <recycle-scroller
          :items="computedJobs"
          :item-size="72"
          list-tag="div"
          list-class="job-body"
          item-tag="div"
          item-class="flex flex-col flex-nowrap p-2 whitespace-nowrap group"
          key-field="id"
        >
          <template #default="{ item: job }">
            <div class="job-row relative">
              <div>
                <yb-icon v-if="job.state === 'Error' || job.state === 'Abandoned'" class="h-5 w-5 text-yb-lava" icon="status_offline" />
                <yb-icon v-else-if="job.state === 'Completed'" class="h-5 w-5 text-yb-brand-primary" icon="status_healthy" />
                <yb-processing v-else class="h-6 w-6" />
              </div>

              <div class="font-semibold py-2 pl-2 pr-8 w-5">
                <div class="flex flex-row items-center flex-shrink-0">
                  <yb-icon v-if="job.operation.match(/UPGRADE/)" class="yb-button-icon flex-shrink-0" icon="upgrade" />
                  <yb-icon v-else-if="job.operation.match(/INSTANCE/)" class="yb-button-icon flex-shrink-0" icon="instance" />
                  {{ translate(job.operation) }}
                </div>
              </div>

              <div class="pr-8 py-2 !min-w-1/5">
                <span class="font-semibold mr-2">Submitted</span><span>{{ formatDateTime(job.creationTimestamp) }}</span>
              </div>

              <div class="pr-8 py-2" v-tooltip="job.instanceName">
                <span class="font-semibold mr-2">Name</span><span>{{ job.instanceName }}</span>
              </div>

              <div class="pr-8 py-2">
                <span class="font-semibold mr-2">Duration</span><span>{{ humanizeMs(jobDuration(job)) }}</span>
              </div>

              <div class="pr-4 py-2">
                <span class="font-semibold mr-2">State</span><span>{{ job.state }}</span>
              </div>
            </div>

            <div class="flex flex-row flex-nowrap pr-16 justify-between relative">
              <div class="flex-grow truncate" v-if="job.state === 'Error'">
                <div class="text-yb-lava truncate mb-3 ml-4">
                  {{ job.summary }}
                </div>
                <div v-tooltip="'Details...'" class="text-yb-lava font-medium cursor-pointer space-x-4 absolute right-5 -top-7" @click="jobDetail = job">
                  <yb-icon icon="warning" class="yb-button-icon" />
                </div>
              </div>
              <div class="invisible group-hover:visible pl-4 place-self-end flex flex-row justify-end space-x-4 absolute top-0 right-0 yb-bg-content">
                <div v-if="job.operation === 'Diagnostics' && job.state === 'Completed'">
                  <a ref="diagsAnchor" target="_blank" :href="getDiagsLink(job)" class="text-sm yb-link font-medium">Download</a>
                </div>
                <div v-if="job.operation !== 'Diagnostics' && !String(job.state).match(/Completed|Error|Abandoned/i) && !job.cancel">
                  <a target="_blank" @click="cancelJob(job)" class="text-sm yb-link font-medium">Cancel</a>
                </div>
                <div v-if="String(job.state).match(/Completed|Error|Abandoned/i)">
                  <a target="_blank" @click="deleteJob(job)" class="text-sm yb-link font-medium">Delete</a>
                </div>
              </div>
            </div>
          </template>
        </recycle-scroller>
      </table>
    </div>

    <transition name="dialog">
      <yb-modal
        v-if="jobDetail"
        show
        title="Job Detail"
        type="form"
        icon="warning"
        dialog-classes="min-w-800"
        content-classes="pb-2"
        @close="jobDetail = null"
        @submit="jobDetail = null"
      >
        <div class="yb-view flex flex-col p-2">
          <table class="yb-view-header table-job-detail">
            <tr>
              <td>Instance</td>
              <td>{{ jobDetail?.instanceName }}</td>
            </tr>
            <tr>
              <td>Operation</td>
              <td>{{ translate(jobDetail?.operation) }}</td>
            </tr>
            <tr>
              <td>Timestamp</td>
              <td>{{ formatDateTime(jobDetail?.creationTimestamp) }}</td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>{{ jobDetail && humanizeMs(jobDuration(jobDetail)) }}</td>
            </tr>
            <tr>
              <td>Summary</td>
              <td>{{ jobDetail?.summary }}</td>
            </tr>
            <tr>
              <td>Details</td>
              <td />
            </tr>
          </table>
          <pre class="yb-view-content-auto whitespace-prewrap text-xs border yb-border-content rounded-md p-2 mx-2 mb-4 mt-1">{{ jobDetail?.detail }}</pre>
          <div class="yb-view-footer flex flex-row justify-end space-x-2 mx-2">
            <yb-button
              v-yb-clipboard:text="jobDetailsFor(jobDetail)"
              class="yb-button-default"
              clipboard-label="(Job Details)"
            >
              <yb-icon class="yb-button-icon" icon="clipboard" />
              Copy to Clipboard
            </yb-button>
            <button class="yb-button-primary" type="submit">
              Close
            </button>
          </div>
        </div>
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import { humanizeMs, formatDateTime } from '@/filters'
import instanceService from '@/services/instanceService'
import { errorHandler } from '@/services';

export default {
  props: {
    jobs: {
      type: Array,
      required: true
    }
  },
  emits: ['refresh'],
  data() {
    return {
      jobsSearch: '',
      jobDetail: null
    }
  },
  computed: {
    computedJobs() {
      if (!this.jobs) {
        return []
      }

      return this.jobs.filter((job) => {
        if (!this.jobsSearch) {
          return true
        }
        return String(job?.input?.name).match(new RegExp(this.jobsSearch, 'i')) ||
          String(this.translate(job?.type)).match(new RegExp(this.jobsSearch, 'i'))
      })
    }
  },
  methods: {
    humanizeMs,
    formatDateTime,
    translate(type) {
      if (type === 'Create') {
        return 'Create Instance'
      } else if (type === 'Destroy' || type === 'Delete') {
        return 'Delete Instance'
      } else if (type === 'ChangeSharedServices') {
        return 'Resize Instance'
      } else if (type === 'Suspend') {
        return 'Suspend Instance'
      } else if (type === 'Resume') {
        return 'Resume Instance'
      } else if (type === 'SetVersion') {
        return 'Upgrade Instance'
      } else if (type === 'Diagnostics') {
        return 'Perform Instance Diagnostics'
      } else if (type === 'SYSTEM_RESIZE') {
        return 'System Resize'
      } else if (type === 'MANAGER_UPGRADE') {
        return 'Upgrade Manager'
      }

      return type
    },

    getDiagsLink(job) {
      return "/download/diagnostics/"  + job.namespace + "/" + job.name
    },

    jobDuration(job) {
      if (!job) {
        return 0
      }

      if (job.completionTimestamp) {
        return +new Date(job.completionTimestamp) - +new Date(job.creationTimestamp)
      } else if (job.lastReportedTimestamp && job.lastReportedTimestamp !== job.creationTimestamp) {
        return +new Date(job.lastReportedTimestamp) - +new Date(job.creationTimestamp)
      } else {
        return +new Date() - +new Date(job.creationTimestamp)
      }
    },

    jobDetailsFor(job) {
      let result = ''
      result += `Instance:  ${job?.instanceName}\n`
      result += `Operation: ${this.translate(job?.operation)}\n`
      result += `Timestamp: ${formatDateTime(job?.creationTimestamp)}\n`
      result += `Duration:  ${humanizeMs(this.jobDuration(job))}\n`
      result += `Summary:   ${job?.summary}\n`
      result += `Details:\n\n${job?.detail}\n`
      return result
    },

    async cancelJob(job) {
      try {
        await useDialogs().confirm('Are you sure you want to cancel this task?')
      } catch (cancel) {
        return
      }
      try {
        await instanceService.cancelInstanceTask(job.namespace, job.name)
        this.$emit('refresh')
      } catch (e) {
        errorHandler.thrown(e, 'Could not cancel this instance task')
      }
    },

    async deleteJob(job) {
      try {
        await useDialogs().confirm('Are you sure you want to delete this task?')
      } catch (cancel) {
        return
      }
      try {
        await instanceService.deleteInstanceTask(job.namespace, job.name)
        this.$emit('refresh')
      } catch (e) {
        errorHandler.thrown(e, 'Could not delete this instance task')
      }
    }
  }
}
</script>

<style scoped lang="postcss">

:deep(.vue-recycle-scroller) {
  @apply h-full w-full;
}

:deep(.job-body) {
  @apply w-full block overflow-x-auto;
}

.job-row {
  @apply select-none flex flex-row flex-nowrap items-center;
}

.job-row > div {
  @apply truncate overflow-hidden;
}

.job-row > div:not(:first-child) {
  @apply w-1/5;
  min-width: 150px;
}

.job-row > div:first-child {
  @apply pr-8 pt-2 w-0;
}

.job-row > div:not(:nth-child(1)) {
  @apply pr-2 border-t border-b border-yb-gray-faint dark:border-yb-gray-medium bg-yb-gray-faintest dark:bg-yb-gray-alt-darker;
}

.job-row > div:nth-child(2) {
  @apply border-l rounded-tl rounded-bl;
}

.job-row > div:last-child {
  @apply border-r rounded-tr rounded-br;
}

:deep(.yb-icon) {
  display: inline-block;
  line-height: 48px;
}

.table-job-detail td {
  @apply px-2 py-0.5 align-top;
}

.table-job-detail td:first-child {
  @apply font-semibold;
}

</style>
