<template>
  <div class="w-96 h-48">
    <div class="grid grid-cols-1 gap-6">
      <yb-material-select
        v-model="bytescale"
        :options="byteScaleUnits"
        label="Byte Quantity Units"
        item-id="value"
        item-label="label"
        data-test-id="byte-scale"
        help="Specify the way in which large numbers are abbreviated"
      />
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  name: 'YbEditorOptions',
  data() {
    return {
      byteScaleUnits: [
        { value: 1000, label: 'Display in Kilobytes (power of 10, KB, MB, etc.)' },
        { value: 1024, label: 'Display in Kibibytes (power of 2, KiB, MiB, etc.)' }
      ]
    }
  },
  computed: {
    bytescale: sync('settings/bytescale', false)
  }
}
</script>
