<template>
  <div class="yb-view">
    <tabs ref="tabs" class="yb-view-content">
      <tab :title="queriesTabTitle">
        <yb-task-queries />
      </tab>
      <tab v-if="$hasRole('consumeradmin') && this.$deploymentMode !== 'ce'" :title="jobsTabTitle">
        <div v-if="loadingJobs" class="h-full">
          <yb-loading class="self-center text-center" />
        </div>
        <yb-task-jobs v-else :jobs="jobs" @refresh="refreshJobs" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import YbTaskQueries from './TaskQueries.vue'
import YbTaskJobs from './TaskJobs.vue'
import apollo from '@/apollo'
import INSTANCE_TASKS from '@/graphql/instanceTasks.gql'
import { tasksService } from '@/services'

export default {
  components: {
    YbTaskQueries,
    YbTaskJobs
  },
  props: {
    tasksTab: String
  },
  data() {
    return {
      jobs: [],
      loadingJobs: false
    }
  },
  computed: {
    queries() {
      return tasksService.taskState.tasks
    },
    jobsRunning() {
      const { jobs } = this
      if (!jobs || !jobs.length) {
        return 0
      } else {
        return jobs.filter(job => !job.completionTimestamp).length
      }
    },
    queriesTabTitle() {
      const { queries } = this
      if (!queries || !queries.length) {
        return 'Queries'
      } else {
        return `Queries (${queries.length} running)`
      }
    },
    jobsTabTitle() {
      const { jobsRunning } = this
      if (!jobsRunning) {
        return 'Jobs'
      } else {
        return `Jobs (${jobsRunning} running)`
      }
    }
  },
  watch: {
    tasksTab(_) {
      this.setActiveTab()
    }
  },
  async mounted() {
    this.setActiveTab()
    if (this.$hasRole('consumeradmin') && this.$deploymentMode !== 'ce') {
      this._jobsInterval = window.setInterval(this.loadJobs.bind(this), 5000)
      this.refreshJobs()
    }
  },
  beforeUnmount() {
    this._jobsInterval && window.clearInterval(this._jobsInterval)
    delete this._jobsInterval
  },
  methods: {
    async loadJobs() {
      const response = await apollo.query({
        query: INSTANCE_TASKS
      })
      this.jobs = (response?.data?.instanceTasks || []).sort((j1, j2) => {
        const d1 = new Date(j1.creationTimestamp)
        const d2 = new Date(j2.creationTimestamp)
        return +d2 - +d1
      })
    },
    async refreshJobs() {
      this.loadingJobs = true
      try {
        await this.loadJobs()
      } finally {
        this.loadingJobs = false
      }
    },
    setActiveTab() {
      if (!this.$refs?.tabs) {
        return
      }
      if (this.tasksTab === 'jobs') {
        this.$refs.tabs.switchTab(null, 1, false)
      } else {
        this.$refs.tabs.switchTab(null, 0, false)
      }
    }
  }
}
</script>
