<template>
  <div>
    <div>
      <span class="font-base text-black dark:text-white">Choose Your Theme</span> <span>(Change the appearance of Yellowbrick Manager throughout).</span>
    </div>
    <div class="grid grid-cols-1 gap-2 mt-2 pr-1">
      <div
        v-for="(d, index) in displayModes"
        :key="index"
        class="p-6 border yb-border-content rounded-sm hover:ring-2 ring-yb-brand-primary cursor-pointer flex flex-row flex-nowrap yb-app-pane"
        @click="displayMode = d.value"
      >
        <div class="mr-4">
          <input :id="'theme-' + d.value" v-model="displayMode" type="radio" :value="d.value">
        </div>
        <div class="flex-grow flex-1">
          <label :for="'theme-' + d.value">
            <span class="font-semibold">{{ d.label }}</span>
            <div class="text-sm font-light">{{ d.description }}</div>
          </label>

          <div class="demo" :class="d.demoClass">
            <div class="w-6 h-6 m-2">
              <yb-icon icon="welcome" />
            </div>
            <div>Welcome to Yellowbrick Manager</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const themeKey = 'YM.theme'

export default {
  data() {
    return {
      displayMode: window.localStorage[themeKey] || 'auto',
      displayModes: [
        {
          value: 'auto',
          label: 'Sync with system default settings',
          description: 'Automatically switch between light and dark themes when your system does.',
          demoClass: 'system'
        },
        {
          value: 'light',
          label: 'Light Mode',
          demoClass: 'block light'
        },
        {
          value: 'dark',
          label: 'Dark Mode',
          demoClass: 'block dark'
        }
      ]
    }
  },
  watch: {
    displayMode(_) {
      if (_ === 'light' || _ === 'dark' || _ === 'auto') {
        this.$store.set('settings/theme', _)
      }
      // Store value.
      if (!_) {
        window.localStorage.removeItem(themeKey)
        _ = 'os'
      } else {
        window.localStorage[themeKey] = _
      }

      // Update DOM.
      if (!_ || _ === 'auto') {
        const mode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
        _ = mode
        this.$store.set('settings/theme', mode)
      }
      if (_ === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
      if (_ === 'light') {
        document.documentElement.classList.add('light')
      } else {
        document.documentElement.classList.remove('light')
      }
    }
  }
}
</script>

<style scoped lang="postcss">

.demo {
  @apply border mt-2 py-1 px-2 rounded-sm yb-bg-content flex flex-row flex-nowrap items-center w-full;
}

.demo .yb-icon {
}

.demo.system {
  @apply hidden;
}

.demo.light {
  @apply border-yb-gray-lightest bg-white text-yb-gray-medium;
}

.demo.dark {
  @apply border-yb-gray-medium bg-yb-gray-mud text-yb-gray-lightest;
}

.demo.dark .yb-icon {
  @apply text-yb-validate;
}

</style>
