<template>
  <div class="yb-view">
    <div class="yb-view-content-auto text-sm font-light pb-24">
      <table class="table w-full">
        <tbody>
          <template v-for="(container, index) in queriesAndHistory" :key="'d' + index">
            <tr>
              <td colspan="5">
                <div v-if="container.items.length > 0" class="w-full flex flex-row justify-between items-center">
                  <div class="yb-menu-heading !p-0">
                    {{ container.type }} ({{ container.items.length }})
                  </div>
                  <a v-if="index !== 0" v-tooltip="'Clear recent query activity'" href="#" class="hover:underline flex items-center text-sm" @click="clearHistory">
                    <yb-icon class="yb-button-icon mr-1" icon="delete-circle" />
                    Clear
                  </a>
                </div>
              </td>
            </tr>

            <template v-for="query in orderQueries(container.items)" :key="'d' + index + '-q-' + query.query_id">
              <tr
                class="query-row p-2 whitespace-nowrap select-none cursor-pointer"
                :class="{'query-hover': query.query_id === hoverQuery}"
                @click="toggleDetailQuery(query, container.historical)"
                @mouseover="hoverQuery = query.query_id"
                @mouseleave="hoverQuery = 0"
              >
                <td :class="{'detail-query': query.query_id == detailQuery}">
                  <div>
                    <yb-icon v-if="query.state === 'error'" v-tooltip="'State: error ' + query.error_code + ', [' + query.error_message + ']'" class="h-5 w-5 text-yb-lava" icon="status_offline" />
                    <yb-icon v-else-if="query.state === 'cancel'" v-tooltip="'State: cancelled'" class="h-5 w-5 text-yb-sandbox" icon="status_degraded" />
                    <yb-icon v-else-if="query.state === 'completing'" v-tooltip="'State: completing'" class="h-5 w-5 text-yb-seafoam" icon="info" />
                    <yb-icon v-else-if="String(query.state).match(/done|restart user|restart error/)" v-tooltip="'State: ' + query.state" class="h-5 w-5 text-yb-brand-primary" icon="status_healthy" />
                    <yb-processing v-else class="h-6 w-6" />
                  </div>
                </td>

                <td class="px-2">
                  <span class="font-semibold mr-2"><span v-if="index === 0">Started on</span><span v-else>Completed at</span></span><span>{{ formatDateTime((index === 0 ? query.submit_time : query.done_time)) }}</span>
                </td>

                <td>
                  <span class="font-semibold mr-2">Duration</span><span>{{ humanizeMs(query.total_ms) }}</span>
                </td>

                <td>
                  <div v-if="(query.state === 'run' || query.state === 'client wait' || query.state === 'done' || query.state === 'error')" class="flex flex-row flex-nowrap items-center">
                    <template v-if="query.io_read_bytes > 0 || query.io_write_bytes > 0 || query.io_spill_write_bytes > 0">
                      <span class="font-semibold mr-1">I/O</span>
                    </template>
                    <template v-if="query.io_read_bytes > 0">
                      <span v-tooltip="'I/O Read (in bytes)'" class="mx-0.5 circle-sm">r</span>
                      <span v-tooltip="detailNumber(query.io_read_bytes)">{{ bytes(query.io_read_bytes) }}</span>
                    </template>
                    <template v-if="query.io_write_bytes > 0">
                      <span v-tooltip="'I/O Write (in bytes)'" class="mx-0.5 circle-sm">w</span>
                      <span v-tooltip="detailNumber(query.io_write_bytes)">{{ bytes(query.io_write_bytes) }}</span>
                    </template>
                    <template v-if="query.io_spill_write_bytes > 0">
                      <span v-tooltip="'I/O Spill Write (in bytes)'" class="mx-0.5 circle-sm">s</span>
                      <span v-tooltip="detailNumber(query.io_spill_write_bytes)">{{ bytes(query.io_spill_write_bytes) }}</span>
                    </template>
                  </div>
                </td>

                <td>
                  <div v-if="(query.state === 'run' || query.state === 'client wait' || query.state === 'done' || query.state === 'error') && query.rows_inserted > 0" class="flex flex-row flex-nowrap items-center">
                    <template v-if="query.rows_inserted > 0 || query.rows_deleted > 0">
                      <span class="mr-1">Rows</span>
                    </template>
                    <template v-if="query.rows_inserted > 0">
                      <span v-tooltip="'Rows Inserted'" class="mx-0.5 circle-sm">+</span>
                      <span v-tooltip="detailNumber(query.rows_inserted)">{{ humanizeNumber(query.rows_inserted, 0) }}</span>
                    </template>
                    <template v-if="query.rows_deleted > 0">
                      <span v-tooltip="'Rows Deleted'" class="mx-0.5 circle-sm">-</span>
                      <span v-tooltip="detailNumber(query.rows_deleted)">{{ humanizeNumber(query.rows_deleted, 0) }}</span>
                    </template>
                  </div>
                </td>
              </tr>

              <tr
                class="detail relative select-none"
                :class="{'detail-query': query.query_id == detailQuery}"
                @click="toggleDetailQuery(query, container.historical)"
              >
                <td />
                <td colspan="4">
                  <div class="text-sm self-start ml-6 mr-2 absolute top-2 right-0 flex flex-col justify-end space-y-1">
                    <a
                      v-if="index === 0 && (!!query.done_time || !String(query.state).match(/^(done|error|cancel)$/))"
                      v-yb-confirm="cancel.bind(this, query)"
                      v-tooltip="'Cancel this query'"
                      href="#"
                      class="hover:underline flex items-center"
                      message="Are you sure you want to cancel this query?"
                      data-test-id="cancel-tasks-query"
                    >
                      <yb-icon class="yb-button-icon yb-error-text mr-1" icon="delete-circle" />
                      Cancel
                    </a>
                    <a v-if="index === 0 && query.type === 'load'" v-tooltip="'Monitor load activity'" href="#" class="hover:underline flex items-center" @click.stop="monitorLoad(query, false)">
                      <yb-icon class="yb-button-icon mr-1" icon="info" />
                      Monitor
                    </a>
                    <a v-if="index === 1 && query.type === 'load'" v-tooltip="'View load activity report'" href="#" class="hover:underline flex items-center" @click.stop="monitorLoad(query, true)">
                      <yb-icon class="yb-button-icon mr-1" icon="info" />
                      Report
                    </a>
                    <a v-tooltip="'Display query details'" href="#" class="hover:underline flex items-center" @click.stop="selectedQuery = query, showQueryDetails = container.historical ? 2 : 1">
                      <yb-icon class="yb-button-icon mr-1" icon="info" />
                      Details
                    </a>
                  </div>

                  <table class="query-detail-table border-collapse">
                    <tr>
                      <td class="font-semibold mr-2">
                        Instance
                      </td><td class="pr-4">
                        {{ query.instance && query.instance.name }}
                      </td>
                      <td class="font-semibold mr-2 pad">
                        ID
                      </td>
                      <td class="">
                        <span v-yb-clipboard:text="query.query_id" v-tooltip="'Copy query ID to clipboard'" class="cursor-pointer hover:underline" :allow-event="false" clipboard-label="(Query ID)">{{ query.query_id }}</span>
                        <a
                          v-yb-clipboard:text="query.query_id"
                          v-tooltip="'Copy query ID to clipboard'"
                          href="#"
                          class="align-middle mr-2"
                          :allow-event="false"
                          clipboard-label="(Query ID)"
                        >
                          <div class="inline-block w-3.5 h-3.5 ml-1">
                            <yb-icon icon="clipboard" />
                          </div>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-semibold mr-2">
                        Cluster
                      </td><td>{{ query.cluster_name }}</td>
                      <td class="font-semibold mr-2 pad">
                        Type
                      </td><td class="">
                        {{ query.type }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-semibold mr-2">
                        Database
                      </td><td>{{ query.database_name }}</td>
                      <td class="font-semibold mr-2 pad">
                        State
                      </td>
                      <td>
                        {{ query.state }}
                        <span v-if="query.state == 'error'" class="text-yb-lava font-semibold">
                          &nbsp;[{{ query.error_code }}&nbsp;{{ query.error_message }}]
                        </span>
                        <span v-if="!String(query.state).match(/done|completing|error/) && query.blocked">
                          ( {{ query.blocked }} )
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr
                :class="{'detail-query': query.query_id == detailQuery}"
                @click="toggleDetailQuery(query, container.historical)"
              >
                <td />
                <td class="p-0" colspan="4">
                  <div class="status-wrapper-sql pl-1 pt-1 mb-2 flex flex-row flex-nowrap items-start overflow-hidden">
                    <yb-highlight language="sql" :content="query.query_text" :wrap="false" class="w-full h-full truncate text-sm !border-0 !py-0" data-test-id="query-tasks-text" />
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="5">
                  <div class="text-yb-lava mb-3 ml-4">
&nbsp;
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>

      <transition name="drawer">
        <yb-modal
          v-if="showQueryDetails"
          show
          title="Query Details"
          type="form"
          layout="drawer"
          dialog-classes=""
          drawer-width="yb-side-xl"
          data-test-id="show-query-details"
          @close="showQueryDetails = 0, selectedQuery = null"
        >
          <template #default="{ dialogController }">
            <yb-query-details
              v-if="showQueryDetails"
              :dialog-controller="dialogController"
              :query-id="selectedQuery.query_id"
              :query-text="selectedQuery.query_text"
              :instance-name="selectedQuery.instance.name"
              :instance-id="selectedQuery.instance.id"
              :historical="showQueryDetails == 2"
            />
          </template>
        </yb-modal>
      </transition>

      <yb-load-monitor
        v-if="loadMonitor"
        :database="loadMonitor.database"
        :instance="loadMonitor.instance"
        :load-tags="loadMonitor.tags"
        :load-session-key="loadMonitor.session_key"
        :load-response="loadMonitor.loadResponse || (loadComplete ? {} : null)"
        :title="(loadComplete ? 'Loaded: ' : 'Loading: ') + loadMonitor.schema + '.' + loadMonitor.table"
        @close="loadMonitor = false"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import YbLoadMonitor from '@/app/load/LoadMonitor.vue'
import YbQueryDetails from '@/app/instance/QueryDetails.vue'
import { tasksService, jolokiaService, mbeans, connectionService } from '@/services'
import { bytes, humanizeNumber, number, humanizeMs, formatDateTime } from '@/filters'
import { sortBy } from '@/util/functions'

export default {
  components: {
    YbQueryDetails,
    YbLoadMonitor
  },
  data () {
    return {
      loadMonitor: null,
      loadComplete: false,
      showQueryDetails: 0,
      selectedQuery: null,
      detailQuery: 0,
      hoverQuery: 0
    }
  },
  computed: {
    queriesAndHistory() {
      return [
        { type: 'Active', items: this.queries, historical: false },
        { type: 'Recent', items: this.history, historical: true }
      ]
    },
    queries() {
      return tasksService.taskState.tasks
    },
    history: get('tasks/settings@history', false)
  },
  methods: {
    humanizeMs,
    humanizeNumber,
    formatDateTime,
    bytes,
    orderQueries(queries) {
      return sortBy(queries.map((query) => {
        query.submit_time_timestamp = new Date(query.submit_time).getTime()
        return query
      }), 'submit_time_timestamp').reverse()
    },
    clearHistory() {
      this.$store.dispatch('tasks/clearHistory')
    },
    cancel(query) {
      // Double check if we know about final state here.
      const q = this.history.find(q => q.query_id === query.query_id) ||
                this.queries.find(q => q.query_id === query.query_id)
      if (q && (!!q.done_time || String(q.state).match(/^(done|error|cancel)$/))) {
        this.$dialogs.info('Cannot cancel query as it has already completed.')
      } else {
        tasksService.cancel(query)
      }
    },
    async monitorLoad(query, completed) {
      this.loadComplete = completed

      // Make an attempt at finding the load via sys.load.  If we can, then monitoring is possible.  Otherwise, no dice.
      try {
        const sql = completed
          ? `select table_name, schema_name, session_key from sys.log_load where tags = '${query.tags}' and start_time >= '${query.submit_time}' order by start_time asc limit 1`
          : `select table_name, schema_name, session_key from sys.load where tags = '${query.tags}' and state in ('STARTING', 'RUNNING') order by start_time asc limit 1`
        const connected = await connectionService.connect(query.instance?.id)
        jolokiaService.start()
        const result = await jolokiaService.execute(mbeans.datasources, 'queryEx', [query.database_name, sql, 'ym', 0])
        if (result && result.rows && result.rows.length === 1) {
          const table = result.rows[0].table_name
          const schema = result.rows[0].schema_name
          const session_key = result.rows[0].session_key
          this.$store.dispatch('tasks/addLoad', this.loadMonitor = { tags: query.tags, database: query.database_name, instance: query.instance || {}, session_key, table, schema, loadResponse: null })
          return
        }
      } catch (e) {
        console.log('Could not monitor load for query', query)
        console.error(e)
      }
      this.$dialogs.info('Cannot monitor load for this query.')
    },
    ensureQueryText(query, historical) {
      tasksService.getQueryText(query, historical)
        .catch((e) => {}) // swallow any error that occurs here.
    },
    toggleDetailQuery(query, historical) {
      const { query_id } = query
      if (this.detailQuery === query_id) {
        this.detailQuery = 0
      } else {
        this.detailQuery = query_id
      }
      this.ensureQueryText(query, historical)
    },
    detailBytes(n) {
      return `${bytes(n, 0)} (${number(n, 0)})`
    },
    detailNumber(n) {
      return `${humanizeNumber(n, 0)} (${number(n, 0)})`
    }
  }
}
</script>

<style scoped lang="postcss">
tr.query-row {
  @apply select-none;
}

tr.query-row.query-hover td:not(:first-child) {
  @apply !bg-yb-rollover dark:!bg-yb-gray-light;
}

tr.query-row td:first-child {
  @apply px-4 pt-2 w-0;
}

tr.query-row td.detail-query:first-child {
  @apply bg-yb-gray-fainter dark:bg-yb-gray-medium;
}

tr.query-row td:not(:nth-child(1)) {
  @apply border-t border-b border-yb-gray-faint dark:border-yb-gray-medium bg-yb-gray-faintest dark:bg-yb-gray-alt-darker w-1/4;
}

tr.query-row td:nth-child(2) {
  @apply border-l rounded-tl rounded-bl !w-1/3;
}

tr.query-row td:last-child {
  @apply border-r rounded-tr rounded-br;
}

tr.detail {
  display: none;
}

tr.detail.detail-query {
  display: table-row;
  @apply bg-yb-gray-fainter dark:bg-yb-gray-medium;
}

tr .status-wrapper-sql {
  @apply max-h-16 !border-0;
  @apply max-w-sm md:max-w-md xl:max-w-lg 2xl:max-w-2xl 3xl:max-w-4xl;
}

tr.detail-query {
  @apply bg-yb-gray-fainter dark:bg-yb-gray-medium;
}

tr.detail-query .status-wrapper-sql {
  @apply !w-full;
  @apply !max-h-48;
  @apply !pr-20;
}

tr.detail-query .status-wrapper-sql :deep(code) {
  @apply !whitespace-pre-wrap;
  display: -webkit-box !important;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

table.query-detail-table td {
  @apply pl-1 pr-4;
}

table.query-detail-table td.pad {
  @apply pl-8;
}

.yb-icon {
  display: inline-block;
  line-height: 48px;
}
</style>
