<template>
  <nav class="bg-yb-black dark:bg-yb-black dark:border-b dark:border-yb-gray-medium">
    <yb-error
      v-if="banner"
      class="h-10 px-1 sm:px-4"
      :error-message="banner.message"
      :show-close="false"
      :show-error="true"
      :is-message="!banner.error"
    />

    <yb-error
      v-else-if="license.warning"
      class="h-10 px-1 sm:px-4"
      error-message=" "
      :show-close="false"
      :show-error="true"
      :is-banner="true"
    >
      <div class="inline-block">{{ license.warning }}</div>
      <router-link class="cursor-pointer inline-block ml-4 underline !font-medium" :to="{ name: 'yb-configuration-license' }">
        Manage license
      </router-link><span class="font-normal">, email licensing@yellowbrick.com or contact your account representative.</span>
    </yb-error>

    <div class="flex items-center mx-auto px-1 sm:px-4 h-12 justify-between relative">
      <div class="text-white mr-2 md:mr-16 flex items-center cursor-pointer">
        <div class="h-10 flex w-8 md:w-48 ml-1 md:ml-0 align-middle relative overflow-hidden" @click="home">
          <svg-logo-dark class="absolute md:static top-1.5 -left-24 h-7 md:h-10" />
        </div>
        <div v-if="false" class="uppercase text-xl tracking-wider ml-1 mt-1 font-semibold text-yb-gray-lighter">
          Manager
        </div>
      </div>
      <ul v-show="active" class="navigation-wrapper flex">
        <li v-if="$hasRole('consumeradmin')" class="nav-link" data-test-id="query-tasks" :class="{ 'active': showTasks }" @click="showTasks = !showTasks">
          <div class="top-level-label-wrapper">
            <span v-if="tasks" class="top-level-label-base border rounded bg-transparent text-xs font-semibold py-0 mr-1 px-1.5">{{ tasks }}</span>
            <yb-icon v-else icon="info-bold" :fill="true" class="mr-1 nav-icon stroke-0" />
            <span class="top-level-label">Tasks</span>

            <div v-if="queries.length > 0 || activeTasks > 0" class="tasks-animation" />
          </div>
          <div class="dropdown-wrapper is-narrow">
            <div class="nav-menu-item flex flex-row justify-between items-center" data-test-id="tasks-queries" @click="tasksTab = 'query'">
              <div>Queries</div>
              <span v-if="queries.length > 0" class="top-level-label-base border rounded bg-transparent text-xs font-semibold py-0 mr-2 px-1.5">{{ queries.length }}</span>
            </div>
            <div v-if="$deploymentMode !='ce'" class="nav-menu-item flex flex-row justify-between items-center" data-test-id="tasks-jobs" @click="tasksTab = 'jobs'">
              <div>Jobs</div>
              <span v-if="activeTasks > 0" class="top-level-label-base border rounded-md bg-transparent text-xs font-semibold py-0 mr-2 px-1.5">{{ activeTasks }}</span>
            </div>
          </div>
        </li>
        <li v-else class="nav-link" data-test-id="query-tasks" :class="{ 'active': showTasks }" @click="tasksTab = 'query'; showTasks = !showTasks">
          <div class="top-level-label-wrapper">
            <span v-if="queriesCount" class="top-level-label-base border rounded bg-transparent text-xs font-semibold py-0 mr-1 px-1.5">{{ queriesCount }}</span>
            <yb-icon v-else icon="info-bold" :fill="true" class="mr-1 nav-icon stroke-0" />
            <span class="top-level-label">Tasks</span>
            <div v-if="queries.length > 0" class="tasks-animation" />
          </div>
        </li>

        <li class="nav-link">
          <div v-if="showInstanceSelector" class="top-level-label-wrapper flex flex-row items-center">
            <yb-icon v-if="notHealthyStatusCount == 0" icon="bullet-point" :fill="true" class="nav-icon stroke-0" :class="[getStatusColorClass(overallStatus)]" />
            <div v-else class="inline-block relative nav-icon" :class="[getStatusColorClass(overallStatus)]">
              <svg class="absolute left-0 top-0 fill-current stroke-current" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50" /></svg>
              <div class="absolute left-0 top-0 z-10 text-white text-xs font-semibold w-full h-full yb-center">
                {{ notHealthyStatusCount > 9 ? '9+' : notHealthyStatusCount }}
              </div>
            </div>
            <yb-icon icon="signal" :fill="true" class="ml-1 nav-icon stroke-0" />
            <span class="top-level-label ml-1">Health</span>
            <yb-icon icon="nav-arrow-down" :fill="true" class="text-yellow-500 ml-2 w-2 stroke-0" />
          </div>

          <div class="dropdown-wrapper">
            <div v-if="instancesSearchResults.length > 5 || searchInputTouched" class="search-wrapper px-4 py-2">
              <yb-search-input v-model="instancesSearch" placeholder="Search Instances" wrapper-classes="w-full" input-classes="w-full p-1 yb-input" />
            </div>
            <div class="list-wrapper" :class="{'intances-selector-wrapper': instancesSearchResults.length > 5}">
              <ul class="list-none" :class="{'intances-selector': instancesSearchResults.length > 5}">
                <li
                  v-for="( instance, index) in instancesSearchResults"
                  :key="index"
                  class="status-wrapper px-4"
                  @click="select(instance)"
                >
                  <router-link class="yb-view" :to="{ name: 'yb-instance', params: { instance_id: instance.id } }">
                    <div class="yb-view-content flex flex-row flex-nowrap items-center overflow-hidden truncate space-x-1 hover:font-medium hover:bg-opacity-80">
                      <yb-icon icon="instance" class="w-4 h-4 inline-block flex-grow-0" />
                      <div class="leading-10 truncate">
                        {{ instance.name }}
                      </div>
                    </div>
                    <div class="yb-view-right flex flex-row flex-nowrap items-center">
                      <yb-icon v-tooltip="'Instance State: ' + instance.activeStatus.description" :icon="getOpIcon(instance.activeStatus.description)" class="w-5 h-5 mx-1 inline-block flex-grow-0" :class="getStatusColorClass(instance.activeStatus.description)" />
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-link">
          <div class="top-level-label-wrapper">
            <yb-icon icon="support" fill class="mr-1 fill-current nav-icon stroke-0" />
            <span class="top-level-label">Support</span>
            <yb-icon icon="nav-arrow-down" :fill="true" class="text-yellow-500 w-2 ml-1 stroke-0" />
          </div>

          <div class="dropdown-wrapper is-right-aligned">
            <div class="nav-menu-item" data-test-id="resources" @click="resources">
              Resources
            </div>
            <div class="nav-menu-item" data-test-id="documentation" @click="documentation">
              Documentation
            </div>
            <div class="nav-menu-item" data-test-id="support" @click="support">
              Support
            </div>
            <router-link :to="'/drivers-tools'">
              <div class="nav-menu-item text-base" data-test-id="drivers-tools">
                Drivers & Tools
              </div>
            </router-link>

            <template v-if="showSupportDetails">
              <hr>
              <div v-yb-clipboard:function="supportDetails" v-tooltip="'Copy the details about your current session to the clipboard for use in filing issues/support requests.'" class="nav-menu-item whitespace-nowrap" clipboard-label="(Session Details)" data-test-id="support-details">
                Copy session details to clipboard
              </div>
            </template>
          </div>
        </li>

        <li class="nav-link">
          <div class="top-level-label-wrapper">
            <yb-icon icon="user-circle" fill class="mr-1 nav-icon stroke-0" />
            <span class="top-level-label whitespace-nowrap" data-test-id="username-select">Hi {{ capitalize(userName) }}</span>
            <yb-icon icon="nav-arrow-down" :fill="true" class="text-yellow-500 w-2 ml-1 stroke-0" />
          </div>

          <div class="dropdown-wrapper is-right-aligned">
            <div class="nav-menu-item" data-test-id="change-profile" @click="showProfile = true">
              Profile
            </div>
            <div v-if="resetPasswordAllowed" class="nav-menu-item" data-test-id="change-password" @click="changePassword">
              Change Password
            </div>
            <router-link :to="'/about'">
              <div class="nav-menu-item text-base" data-test-id="about">
                About
              </div>
            </router-link>
            <hr class="mx-2 border-gray-700 dark:border-yb-gray-mediumer">
            <div class="nav-menu-item" data-test-id="logout" @click="logout">
              Logout
            </div>
          </div>
        </li>
      </ul>
      <ul v-if="start" class="navigation-wrapper flex">
        <li class="nav-link">
          <div class="top-level-label-wrapper">
            <yb-icon icon="user-circle" fill class="mr-1 nav-icon stroke-0" />
            <span class="top-level-label whitespace-nowrap">Hi {{ capitalize(userName) }}</span>
            <yb-icon icon="nav-arrow-down" :fill="true" class="text-yellow-500 w-2 ml-1 stroke-0" />
          </div>

          <div class="dropdown-wrapper is-right-aligned">
            <div class="nav-menu-item" data-test-id="change-profile" @click="showProfile = true">
              Profile
            </div>
            <div v-if="resetPasswordAllowed" class="nav-menu-item" data-test-id="change-password" @click="changePassword">
              Change Password
            </div>
            <hr class="mx-2 border-gray-700 dark:border-yb-gray-mediumer">
            <div class="nav-menu-item" data-test-id="logout" @click="$router.logout">
              Logout
            </div>
          </div>
        </li>
      </ul>
    </div>

    <transition name="dialog">
      <yb-modal
        v-if="showProfile"
        :show="showProfile"
        title="Profile Settings"
        dialog-classes="min-w-800"
        data-test-id="user-profile"
        @close="showProfile = false"
      >
        <yb-edit-profile />
      </yb-modal>
    </transition>

    <transition name="drawer">
      <yb-modal
        v-if="showTasks"
        :show="showTasks"
        title="Tasks"
        data-test-id="tasks-modal"
        layout="drawer"
        dialog-classes=""
        drawer-width="yb-side-lg"
        content-classes="px-2 -my-4"
        @close="showTasks = false"
      >
        <yb-tasks :tasks-tab="tasksTab" />
      </yb-modal>
    </transition>
  </nav>
</template>

<script>
import { get, sync } from 'vuex-pathify'
import { getInstance } from '@/auth'
import { tasksService } from '@/services'
import Instance from '@/models/Instance'
import YbEditProfile from '@/app/profile/EditProfile.vue'
import YbTasks from '@/app/tasks/Tasks.vue'
import { capitalize } from '@/filters'
import useInstancesLoader from '@/app/instance/InstancesLoader'
import errorReporter from '@/services/errorReporter'
import { internalHost } from '@/services/constants'

export default {
  components: {
    YbEditProfile,
    YbTasks
  },
  props: {
    active: {
      default: true
    },
    start: {
      default: false
    }
  },
  setup () {
    return {
      ...useInstancesLoader()
    }
  },
  data () {
    return {
      activeInstance: 'Demo',
      instancesSearch: '',
      searchInputTouched: false,
      populating: true,
      showProfile: false,
      showTasks: false,
      tasksTab: 'queries'
    }
  },
  computed: {
    overallStatus() {
      const sortedInstances = this.instances.map(instance => instance.activeStatus.description)
        .sort((i1, i2) => {
          return getStatusScore(i2) - getStatusScore(i1)
        })
        .reverse()
      return sortedInstances.length === 0 ? 'Healthy' : sortedInstances[0]
    },
    offlineStatusCount() {
      const offlineInstances = this.instances.map(instance => instance.activeStatus.description).filter(status => status.toLowerCase() === 'offline')
      return offlineInstances.length
    },
    notHealthyStatusCount() {
      const { overallStatus } = this
      const overallStatusLo = String(overallStatus).toLowerCase()
      const offlineInstances = this.instances.map(instance => instance.activeStatus.description).filter(status => status.toLowerCase() === overallStatusLo)
      return offlineInstances.length
    },
    instancesSearchResults() {
      return this.instancesSearch.length === 0 ? this.instances : this.instances.filter(instance => instance.name.toLowerCase().includes(this.instancesSearch.toLowerCase()))
    },
    history: get('tasks/settings@history', false),
    selectedInstance: sync('global/settings@instance', false),
    showInstanceSelector() {
      return !this.populating
    },
    userName() {
      return getInstance()?.userName || this.$store.get('global/settings@user.name') || ''
    },
    resetPasswordAllowed() {
      return getInstance().resetPasswordAllowed
    },
    queries() {
      return tasksService.taskState.tasks
    },
    activeTasks() {
      return tasksService.taskState.activeTasks
    },
    queriesCount() {
      const { queries } = this
      const count = (queries || []).length
      if (count > 9) {
        return '9+'
      } else if (count > 0) {
        return String(count)
      } else {
        // return '☀';
        return ''
      }
    },
    tasks() {
      const { queries, activeTasks } = this
      const count = (queries || []).length + (activeTasks || 0)
      if (count > 9) {
        return '9+'
      } else if (count > 0) {
        return String(count)
      } else {
        // return '☀';
        return ''
      }
    },
    notices() {
      return this.$notices
    },
    banner() {
      const { notices } = this
      if (!notices) {
        return null
      }
      if (notices.communicationErrors?.global) {
        return { message: 'Yellowbrick Manager is offline.', error: true }
      } else if (notices.communicationErrors?.instance) {
        // Do not report on instances who have gone missing.
        const instance = Instance.query().where('id', notices.communicationErrors.instance.instance).first()
        if (!!instance && instance.online) {
          return { message: `Yellowbrick Manager cannot communicate with this instance [${instance.name}].`, error: true }
        }
      } else if (notices.banner) {
        return { message: notices.banner, error: false }
      }
      return null
    },
    showSupportDetails() {
      return internalHost
    },
    license() {
      const { notices } = this
      if (!notices || useConfig().deploymentMode === 'ce') {
        return {}
      }
      return notices.license
    }
  },
  watch: {
    instancesSearch() {
      this.searchInputTouched = true
    }
  },
  async mounted() {
    this.populating = true
    try {
      await this.$store.dispatch('instance/populate')
      this._populateInterval = window.setInterval(() => {
        this.$store.dispatch('instance/populate')
      }, 5000)

      // Do auto-selection if one instance is present.
      const { selectedInstance, instances } = this
      if (!selectedInstance && instances && instances.length === 1) {
        this.select(instances[0])
      }
    } finally {
      this.populating = false
    }
    false && console.log(this.instancesSearchResults)
  },
  beforeUnmount() {
    this._populateInterval && window.clearInterval(this._populateInterval)
    delete this._populateInterval
  },
  methods: {
    capitalize,
    getStatusColorClass,
    getOpIcon,
    select(instance) {
      const { id } = instance
      this.selectedInstance = { id }
    },
    resources() {
      window.open('https://yellowbrick.com/resources/', '_blank')
    },
    documentation() {
      window.open('https://www.yellowbrick.com/resources/product-documentation/', '_blank')
    },
    support() {
      window.open('https://support.yellowbrick.com/', '_blank')
    },
    async changePassword() {
      try {
        getInstance().resetPassword(this.$store.get('global/settings@user.email'))
      } catch (ignore) {
        // nop
      }
    },
    home() {
      if (this.active || this.start) {
        this.$router.push('/home')
      } else {
        window.open('https://www.yellowbrick.com')
      }
    },
    async supportDetails() {
      return await errorReporter({ message: 'Support Details' }, 'Copy session details to clipboard', this.$route.params.instance_id)
    },
    logout() {
      getInstance().logout()
    }
  }
}
</script>

<style scoped lang="postcss">

.nav-link {
  @apply h-full flex items-center text-white cursor-pointer px-1 font-light sm:px-4 select-none md:relative;
  transition: color 0.25s ease, background-color 0.25s ease;
}

.nav-link:hover {
  @apply bg-yb-black;
}

.nav-link:hover .top-level-label-base,
.nav-link:hover .top-level-label {
  @apply text-white border-white;
}

.nav-link:hover .nav-icon {
  @apply text-white;
  @apply select-none;
}

.nav-link:hover .dropdown-wrapper {
  @apply block bg-yb-black text-white;
}

.nav-link.active {
  @apply bg-yb-rollover;
}

.nav-link .status-counter {
  position: relative;
  text-align: center;
  width: 12px;
}

.nav-link .status-counter:after {
  content: attr(data-status-count);
  position: absolute;
  color: white;
  top: 0px;
  right: 3px;
  font-size: 11px;
  line-height: 12px;
}

.nav-menu-item {
  @apply hover:bg-yb-black hover:text-white hover:font-semibold px-4 leading-10;
}

.nav-menu-item .top-level-label-base {
  @apply dark:text-white dark:border-white;
}

.nav-menu-item:hover .top-level-label-base {
  @apply dark:border-white dark:text-white;
}

.yb-icon,
.top-level-label {
  display: inline-block;
  line-height: 48px;
}

.top-level-label-wrapper {
  @apply flex flex-row items-center;
}

.top-level-label-wrapper .top-level-label {
  @apply text-white;
}

.top-level-label-wrapper .top-level-label-base {
  @apply text-white;
  @apply border-white;
}

.top-level-label-wrapper .nav-icon {
  @apply w-4 h-4;
}

.dropdown-wrapper {
  display: none;
  position: absolute;
  top: 100%;
  min-width: 340px;
  z-index: 10;
  @apply left-36 md:-left-24 right-auto shadow-lg border-solid dark:border dark:border-yb-gray-medium border-t-0 yb-body-color;
}

.dropdown-wrapper.is-narrow,
.dropdown-wrapper.is-right-aligned {
  min-width: 200px;
}

.dropdown-wrapper.is-right-aligned {
  left: initial;
  right: 0;
}

.dropdown-wrapper .actions-wrapper a {
  white-space: nowrap;
}

.dropdown-wrapper .list-wrapper.intances-selector-wrapper {
  position: relative;
}

.dropdown-wrapper .list-wrapper .intances-selector {
  max-height: 200px;
  overflow-y: scroll;
}

.dropdown-wrapper .list-wrapper .intances-selector::-webkit-scrollbar {
  @apply p-16;
}

.dropdown-wrapper .list-wrapper .intances-selector::-webkit-scrollbar-track {
  @apply bg-yb-gray-lighter dark:bg-yb-gray-alt-medium;
}

.dropdown-wrapper .list-wrapper .intances-selector::-webkit-scrollbar-thumb {
  @apply bg-yb-gray-alt-medium dark:bg-black;
}

.dropdown-wrapper .list-wrapper li {
  border-left: solid 3px transparent;
}

.dropdown-wrapper .list-wrapper li td {
  @apply whitespace-nowrap;
}

.dropdown-wrapper .list-wrapper li:not(.list-plain):hover {
  @apply bg-gray-900 text-white;
}

.dropdown-wrapper .list-wrapper li.list-plain:hover {
  @apply bg-yb-gray-fainter dark:bg-yb-gray-medium text-yb-gray-dark dark:text-yb-gray-lighterer;
}

.dropdown-wrapper.wide {
  min-width: 90vw;
  right: 5vw;
  left: 5vw;
}

@media (min-width: 768px) {
  .dropdown-wrapper.wide {
    min-width: 75vw;
    right: -37.5vw;
    left: -37.5vw;
  }
}

@media (min-width: 1024px) {
  .dropdown-wrapper.wide {
    min-width: 60vw;
    right: -10vw;
    left: -50vw;
  }
}

@media (min-width: 1280px) {
  .dropdown-wrapper.wide {
    min-width: 50vw;
    right: -10vw;
    left: -40vw;
  }
}

@media (min-width: 1440px) {
  .dropdown-wrapper.wide {
    min-width: 40vw;
    right: -10vw;
    left: -30vw;
  }
}

.tasks-animation {
  @apply bg-yb-yellow absolute left-0 bottom-0 block rounded-full w-1 h-1 mb-1;

  transition: all 1s ease-in-out;
  animation: ani 1.7s infinite;
}

@keyframes ani {
  0% {
    left: 2%;
  }
  50% {
    left: 94%;
  }
  100% {
    left: 2%;
  }
}

a {
  @apply font-light;
}

</style>
