<template>
  <tabs class="mb-10 min-w-3/4 min-h-500" data-test-id="profile-left-nav">
    <tab title="Appearance" class="mt-3" data-test-id="appearance">
      <yb-appearance-profile />
    </tab>
    <tab title="Settings" class="mt-3" data-test-id="query-settings">
      <yb-settings-profile />
    </tab>
    <tab title="Query Editor" class="mt-3" data-test-id="query-editor">
      <yb-query-editor-profile />
    </tab>
    <tab title="Advanced" class="mt-3" data-test-id="advanced">
      <yb-advanced-profile />
    </tab>
  </tabs>
</template>

<script>
import YbAppearanceProfile from './AppearanceProfile.vue'
import YbSettingsProfile from './SettingsProfile.vue'
import YbQueryEditorProfile from './QueryEditorProfile.vue'
import YbAdvancedProfile from './AdvancedProfile.vue'

export default {
  components: {
    YbAppearanceProfile,
    YbSettingsProfile,
    YbQueryEditorProfile,
    YbAdvancedProfile
  }
}
</script>
