<template>
  <div>
    <h4>Reset Settings</h4>
    <p class="text-sm max-w-sm my-4">
      This option will remove all settings stored with your browser that Yellowbrick Manager uses,
      including removing query editor tabs and content.
    </p>
    <p class="text-sm max-w-sm mb-4">
      <strong>Note: </strong>You will be logged out after choosing this option.
    </p>
    <yb-button v-yb-confirm="reset" label="Reset Settings" class="yb-button-primary-lg" message="Are you sure you want to reset your settings?" />
  </div>
</template>

<script>
import vuexLocalStorage from '@/store/vuexLocalStorage'

export default {
  methods: {
    reset() {
      vuexLocalStorage.reset()
    }
  }
}
</script>
