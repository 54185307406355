<template>
  <div class="w-96">
    <div class="grid grid-cols-1 gap-6">
      <yb-input
        v-model.number="limit"
        label="Fetch Limit"
        type="number"
        :min="1"
        :max="rowsetLimit"
        :validation="v$.limit"
        class="w-full"
        placeholder="Enter row limit"
        layout="material"
        help="Limit of number of rows Yellowbrick Manager will fetch and display for a query result.<br><br>Note: this is not the same as specifying a LIMIT for a query, which affects which rows<br> the query retrieves from a larger set of rows the query could have returned."
        data-test-id="fetch-limit"
      />
      <yb-material-select
        v-model="fontSize"
        :options="fontSizes"
        label="Font Size"
        item-id="value"
        item-label="label"
        :search-limit="20"
        data-test-id="font-size"
        help="Specify the font used in the query editor"
      />
      <yb-material-select
        v-model="indentLevel"
        :options="indentLevels"
        label="Indent Level"
        item-id="value"
        item-label="label"
        :search-limit="20"
        help="Specify the number of spaces used to indent code, when formatting or using TAB to indent selected text blocks."
      />
      <yb-material-select
        v-model="queryBatchPolicy"
        :options="queryBatchPolicies"
        label="Query Batch Policy"
        item-id="value"
        item-label="label"
        data-test-id="query-batch-policy"
        help="Choose how results are handled when executing multiple statements in the query editor."
      />
      <yb-input
        v-model="minimap"
        label="Display minimap overview"
        type="checkbox"
        class="text-sm"
        label-class="flex-grow"
        help="Toggle the display of the editor overview"
        data-test-id="minimap-overview"
      />
      <yb-input
        v-model="useDatabaseTimeZone"
        label="Format time in database/session timezone"
        type="checkbox"
        class="text-sm"
        label-class="flex-grow"
        help="When formatting time, use the database settings for timezone.  Default is to use system/browser timezone."
        data-test-id="use-database-timezone"
      />
      <yb-input
        v-model="useMonospacedFont"
        label="Display Resuls in Monospaced Font"
        type="checkbox"
        class="text-sm"
        label-class="flex-grow"
        data-test-id="font-mono"
        help="Specify whether to use monospaced font used in the query editor results view"
      />
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { integer, maxValue } from '@vuelidate/validators'
import { sync } from 'vuex-pathify'
import { rowsetLimit } from '@/services/constants'

export default {
  name: 'YbEditorOptions',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      fontSizes: [
        { value: 'xs', label: 'Extra Small' },
        { value: 'sm', label: 'Small' },
        { value: 'base', label: 'Normal' },
        { value: 'md', label: 'Medium' },
        { value: 'lg', label: 'Large' },
        { value: 'xl', label: 'Extra Large' }
      ],
      indentLevels: [
        { value: '2', label: '2' },
        { value: '4', label: '4' },
        { value: '8', label: '8' }
      ],
      queryBatchPolicies: [
        { value: 'first', label: 'Display first query' },
        { value: 'first-with-rows', label: 'Display first query with results' },
        { value: 'last', label: 'Display last query' },
        { value: 'last-with-rows', label: 'Display last query with results' }
      ],
      rowsetLimit
    }
  },
  computed: {
    ...sync('settings/editor@*', false)
  },
  validations: {
    limit: {
      integer,
      maxValue: maxValue(rowsetLimit)
    }
  }
}
</script>
